import React, {Component} from 'react'
import FileErrorListPage from "./FileErrorListPage"

class FileErrorListContainer extends Component {
    render() {
        return (
            <div className='FileErrorListContainer'>
                <FileErrorListPage/>
            </div>
        )
    }
}

export default FileErrorListContainer