import React, { Component } from 'react'
import LabelPage from './LabelPage'
import DashboardLayout from "../../dashboard/components/DashboardContainer"
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'

class LabelContainer extends Component {

    render() {
        const urlParams = parseSearchQuery()
        const { page = 1, limit = 50 } = urlParams
        const currentPageParsed = Number(page)
        const currentLimitParsed = Number(limit)

        return (
            // <DashboardLayout path={this.props.match.path}>
            <div className="LabelsContainer mt-4">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <h1 className="PageTitle mb-3">List labels</h1>
                </div>
                <div className="site-layout-background p-3">
                    <LabelPage currentPage={currentPageParsed} currentLimit={currentLimitParsed} />
                </div>
            </div>
            // </DashboardLayout>
        )
    }

}

export default LabelContainer