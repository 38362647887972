import React, { Component } from 'react'
import ShippingErrorPage from './ShippingErrorPage'
import DashboardLayout from "../../dashboard/components/DashboardContainer"

class ShippingErrorContainer extends Component {

    render() {

        return (
            // <DashboardLayout path={this.props.match.path}>
            <div className="ExportErrorShipping mt-4">
                <div className="d-sm-flex justify-content-between align-items-center">
                    <h1 className="PageTitle mb-3">Export error shipping</h1>
                </div>
                <div className="site-layout-background p-4">
                    <ShippingErrorPage />
                </div>
            </div>
            // </DashboardLayout>
        )
    }

}

export default ShippingErrorContainer