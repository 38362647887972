import api from './../api'

export const createRelabel = (data) => {
    return api.makeAuthRequest({
        url: `change-labels`,
        method: 'POST',
        data
    })
}

export const getListRelabels = () => {
    return api.makeAuthRequest({
        url: `change-label-requests`,
        method: 'GET'
    })
}

export const downloadRelabel = (id) => {
    const baseUrl = api.getBaseURL()

    return `${baseUrl}/change-label-requests/${id}/uploaded-file`
}