import React, {useState} from 'react'
import CreatedInvoiceValidateModal from "./CreatedInvoiceValidateModal"

function CreatedInvoiceValidate() {
    const [isOpen, _setToggleModal] = useState(false)

    function _onChangeToggleModal() {
        _setToggleModal(!isOpen)
    }

    return (
        <div className='CreatedInvoiceValidate mb-3'>
            <button type="primary" className="btn btn-primary" onClick={_onChangeToggleModal}>
                Create invoice validation
            </button>

            {
                isOpen && <CreatedInvoiceValidateModal isOpen={isOpen} onToggleModal={_onChangeToggleModal}/>
            }
        </div>
    )
}

export default CreatedInvoiceValidate
