import React, {Component} from 'react'
import moment from 'moment'
import humanizeTime from './../../../../helpers/time/humanizeTime'
import PagePagination from "../../../../shared/PagePagination"
import ColInvoiceRecords from './ColInvoiceRecords'
import FilterRecords from './FilterRecords'
import TableLoading from "../../../../shared/TableLoading"
import PropTypes from "prop-types"
import {getItemListReports} from '../../../../services/api/InvoiceValidationServices'
import PubSub from 'pubsub.js'

class InvoiceResults extends Component {
    state = {
        data: [],
        filter: {
            order_number: '',
            partner_tracking_number: '',
            tracking_number: '',
            payment_status: '',
            status: '',
        },
        page: 1,
        pages: 1,
        limit: 20,
        total: 0,
        error: '',
        loading: false
    }

    _token = null

    componentDidMount() {
        this._getListResults()
        this._token = PubSub.subscribe('LOAD_LIST_INVOICE_ITEMS', () => {
            this._getListResults()
        })
    }

    componentWillUnmount() {
        this._token && PubSub.unsubscribe(this._token)
    }

    _getListResults = async () => {
        try {
            const {request_id} = this.props
            const {page, limit, filter} = this.state
            const {status, tracking_number, order_number, partner_tracking_number, payment_status} = filter
            const query = {
                request_id,
                page,
                limit,
                status,
                payment_status,
                order_number,
                tracking_number,
                partner_tracking_number
            }

            this.setState({
                loading: true
            })

            const {data, message, success} = await getItemListReports(request_id, query)

            if(!success) {
                return this.setState({
                    loading: false,
                    error: message || 'Get data error'
                })
            }

            const {results, pages, total} = data

            this.setState({
                data: results,
                pages: pages,
                total: total,
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _onHandleChangeInput = (name, value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: value
            },
            page: 1
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this._getListResults()
            }, 350)
        })
    }

    _onHandleResetFilter = name => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: ''
            },
            page: 1
        }, () => {
            this._getListResults()
        })
    }

    _onHandleChangeStatus = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                status: e.target.value
            },
            page: 1
        }, () => {
            this._getListResults()
        })
    }

    _onHandleChangePaymentStatus = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                payment_status: e.target.value
            },
            page: 1
        }, () => {
            this._getListResults()
        })
    }

    _validateDate = (date) => {
        const momentTime = moment(date)

        return humanizeTime(momentTime)
    }

    _switchPage = (page) => {
        this.setState({
            page: page
        }, () => {
            this._getListResults()
        })
    }

    _genStatus = (status) => {
        if(status === 'done') {
            return <span className="badge badge-success">Done</span>
        } else if(status === 'failed') {
            return <span className="badge badge-danger">Failed</span>
        } else if(status === 'weight_failed') {
            return <span className="badge badge-danger">Failed weight</span>
        } else if(status === 'pending') {
            return <span className="badge badge-secondary">Pending</span>
        } else if(status === 'paid') {
            return <span className="badge badge-success">Paid</span>
        }
    }

    render() {
        const {error, data, filter, page, pages, total, loading} = this.state
        const {request_id, status, shipping_carrier, filename} = this.props

        return (
            <div className="ResultInvoices mt-4">
                <FilterRecords
                    filter={filter}
                    idInvoice={request_id}
                    total={total}
                    filename={filename}
                    onHandleChangePaymentStatus={this._onHandleChangePaymentStatus}
                    onHandleChangeInput={this._onHandleChangeInput}
                    onHandleChangeStatus={this._onHandleChangeStatus}
                    onHandleResetFilter={this._onHandleResetFilter}
                />
                {error && (
                    <div className="text-danger mb-3">{error}</div>
                )}
                <div className="wrapTable">
                    <table className="table">
                        <thead className="thead-light">
                        <tr>
                            <th className="colOrderNumber">Order number</th>
                            <th className="colMessage">Message</th>
                            <th className="colCreated">Created</th>
                            <th className="colCheckingNumber">{shipping_carrier !== 'shippo_us' && 'Tracking number (Foobla)'}</th>
                            <th className="colCheckingNumber">Tracking number{shipping_carrier !== 'shippo_us' && ' (Multran)'}</th>
                            <th className="colPaymentStatus text-center colStatus">Payment Status</th>
                            <th className="text-center colStatus">Status</th>
                        </tr>
                        </thead>
                        {
                            (status !== 'done' && status !== 'processing') ? (
                                <tbody>
                                <tr>
                                    <td colSpan={7}>In processing</td>
                                </tr>
                                </tbody>
                            ) : (
                                loading ?
                                    <TableLoading columnQuantity={7}/>
                                    :
                                    <tbody>
                                    {data.length > 0 ? (
                                        data.map(item => {
                                            const {invoice_records} = item
                                            const {PaymentStatus} = invoice_records[0]
                                            return (
                                                <tr key={item._id}>
                                                    <td className="d-flex">{item.order_number}
                                                        {(item.invoice_records !== undefined && shipping_carrier !== 'shippo_us') && (
                                                            <ColInvoiceRecords records={item.invoice_records}/>
                                                        )}
                                                    </td>
                                                    <td>{item.message}</td>
                                                    <td>{this._validateDate(item.created)}</td>
                                                    <td>
                                                        {(item.labelDetails !== undefined && shipping_carrier !== 'shippo_us') && item.labelDetails.partnerTrackingNumber}
                                                    </td>
                                                    <td>
                                                        {item.labelDetails !== undefined && item.labelDetails.trackingNumber}
                                                    </td>
                                                    <td className="text-center">
                                                        {this._genStatus(PaymentStatus)}
                                                    </td>
                                                    <td className="text-center">
                                                        {this._genStatus(item.status)}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={7}>No results</td>
                                        </tr>
                                    )}
                                    </tbody>
                            )
                        }
                    </table>
                </div>
                {!loading && (
                    <div className="TableNav mt-5 d-flex justify-content-end">
                        <PagePagination page={page} pages={pages} onChangePage={this._switchPage}/>
                    </div>
                )}
            </div>
        )
    }
}

InvoiceResults.propTypes = {
    status: PropTypes.string.isRequired,
    request_id: PropTypes.string.isRequired,
}

export default InvoiceResults