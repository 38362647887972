import React, {Component} from 'react'
import ShippingErrorForm from './ShippingErrorForm'
import FileErrorListContainer from "./file-error-lists/FileErrorListContainer"

class ShippingErrorPage extends Component {

    render() {

        return (
            <div className="ExportErrorShippingPage">
                <div className="ExportErrorShippingForm">
                    <ShippingErrorForm/>
                </div>
                <div className="ListErrorShipping">
                    <FileErrorListContainer/>
                </div>
            </div>
        )
    }

}

export default ShippingErrorPage