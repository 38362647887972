import React, {Component} from 'react'
import RelabelsTable from './table/RelabelsTable'

class RelabelsPage extends Component {
    render() {
        return <div className="RelabelsPage">
            <RelabelsTable/>
        </div>
    }
}

export default RelabelsPage