import React, {Component} from 'react'
import ExportRecords from "./ExportRecords"
import PropTypes from "prop-types"

class FilterRecords extends Component{
    _onHandleChangeInput = e => {
        const {onHandleChangeInput} = this.props
        const {name, value} = e.target

        onHandleChangeInput(name, value)
    }
    render() {
        const {filter, idInvoice, total, onHandleChangeStatus, onHandleResetFilter, filename, onHandleChangePaymentStatus} = this.props

        return (
            <div className="FilterRecords mt-1 mb-3 d-lg-flex justify-content-end align-items-start">
                <div className="FilterStatus d-lg-flex align-items-center mr-3">
                    <div className="position-relative InputWrapper OrderNumberFilter mb-3 mb-lg-0">
                        <i className="iconSearch fa fa-search position-absolute"/>
                        <input onChange={this._onHandleChangeInput} name="order_number" value={filter.order_number} className="form-control" placeholder="Order number"/>
                        {filter.order_number && (
                            <i onClick={() => onHandleResetFilter('order_number')} className="iconClose fa fa-times-circle position-absolute"/>
                        )}
                    </div>
                    <div className="position-relative InputWrapper mb-3 mb-lg-0">
                        <i className="iconSearch fa fa-search position-absolute"/>
                        <input onChange={this._onHandleChangeInput} name="partner_tracking_number" value={filter.partner_tracking_number} className="form-control" placeholder="Tracking number (Foobla)"/>
                        {filter.partner_tracking_number && (
                            <i onClick={() => onHandleResetFilter('partner_tracking_number')} className="iconClose fa fa-times-circle position-absolute"/>
                        )}
                    </div>
                    <div className="position-relative InputWrapper mb-3 mb-lg-0">
                        <i className="iconSearch fa fa-search position-absolute"/>
                        <input onChange={this._onHandleChangeInput} name="tracking_number" value={filter.tracking_number} className="form-control" placeholder="Tracking number (Multran)"/>
                        {filter.tracking_number !== '' && (
                            <i onClick={() => onHandleResetFilter('tracking_number')} className="iconClose fa fa-times-circle position-absolute"/>
                        )}
                    </div>
                    <div className="SelectWrap position-relative mb-3 mb-lg-0 d-inline-block">
                        <select onChange={onHandleChangeStatus} className="form-control StatusFilter">
                            <option value="">All status</option>
                            <option value="done">Done</option>
                            <option value="failed">Failed</option>
                            <option value="weight_failed">Failed weight</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>
                    <div className="SelectWrap position-relative mb-3 mb-lg-0 d-inline-block">
                        <select onChange={onHandleChangePaymentStatus} className="form-control StatusFilter Force">
                            <option value="">All Payment status</option>
                            <option value="pending">Pending</option>
                            <option value="paid">Paid</option>
                        </select>
                        <i className="fa fa-caret-down position-absolute"/>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <ExportRecords
                        request_id={idInvoice}
                        filename={filename}
                        filter={filter}
                    />
                    <div className="total ml-3"><b>{total} {total === 1 ? 'item' : 'items'}</b></div>
                </div>
            </div>
        )
    }
}

FilterRecords.propTypes = {
    filter: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired,
    idInvoice: PropTypes.string.isRequired,
    onHandleChangeStatus: PropTypes.func.isRequired,
    onHandleResetFilter: PropTypes.func.isRequired,
    onHandleChangePaymentStatus: PropTypes.func.isRequired
}

export default FilterRecords