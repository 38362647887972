import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Alert, Button} from "reactstrap"
import {downloadResult} from "../../../../../services/api/ErrorShipping"

class DownloadInvoice extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: ''
        }
    }

    _exportFile = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {id} = this.props
            window.location.href = downloadResult(id)
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    render() {
        const {status} = this.props
        const {error} = this.state

        return (
            <td className='DownloadInvoice Action'>
                {
                    error && <Alert color='danger'>{error}</Alert>
                }
                {
                    status === 'done' &&
                    <Button color='primary' onClick={() => this._exportFile()}>Export shipping cost</Button>
                }
            </td>
        )
    }
}

DownloadInvoice.propTypes = {
    id: PropTypes.string.isRequired
}

export default DownloadInvoice