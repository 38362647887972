import buildQuery from "../../helpers/query/buildQuery"
import api from "../api"

export const getListInvoiceValidation = (params) => {
    const query = buildQuery(params)

    return api.makeAuthRequest({
        url: `requests?${query}`,
        method: 'GET',
    })
}