import React, {Component} from 'react'
import {exportSupplierReportsCSV} from '../../../services/api/InvoiceValidationServices'
import {saveAs} from 'file-saver'

class ExportRecords extends Component {
    state = {
        error: '',
        loading: false
    }

    _handleExportRecords = async () => {
        const {request_id, filter, filename} = this.props
        const {status} = filter
        const query = {
            request_id,
            status
        }

        this.setState({
            loading: true
        })

        const data = await exportSupplierReportsCSV(request_id, query)

        const file = new File([data], `Export_checking_${filename}.csv`, {type: 'application/csv; charset=UTF-8'})
        saveAs(file)

        this.setState({
            loading: false
        })
    }

    render() {
        const {loading, error} = this.state

        return (
            <div className="ExportRecords ml-0">
                <button className="btn btn-primary" disabled={loading} onClick={this._handleExportRecords}>
                    <i className="fas fa-upload mr-1"/> {loading ? 'Exporting...' : 'Export CSV'}
                </button>
                {error && (
                    <div className="text-danger">{error}</div>
                )}
            </div>
        )
    }

}

export default ExportRecords
