import React, {Component} from 'react'
import ConfirmForceApprove from './ConfirmForceApprove'
import PropTypes from "prop-types"
import {forceApproveFailedWeight} from "../../../../services/api/InvoiceValidationServices"
import PubSub from 'pubsub.js'

class ForceApprove extends Component {
    state = {
        loading: false,
        error: '',
        isOpen: false,
        tooltipOpen: false
    }

    _handleToggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    _handleSubmit = async () => {
        const {requestID} = this.props
        this.setState({
            error: '',
            loading: true
        })

        try {
            const {message, success} = await forceApproveFailedWeight(requestID)

            if(!success) return this.setState({
                error: message,
                loading: false
            })

            this.setState({
                loading: false,
                isOpen: false,
            }, () => {
                PubSub.publish('LOAD_DETAIL_INVOICE')
                PubSub.publish('LOAD_LIST_INVOICE_ITEMS')
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })
        }
    }

    render() {
        const {isOpen, error, loading} = this.state
        const {requestID, order_number} = this.props

        return (
            <div>
                <button className="btn btn-primary btn-sm" onClick={this._handleToggleModal}>
                    <i className="fas fa-check mr-1"/> Force Approve
                </button>

                {isOpen && (
                    <ConfirmForceApprove
                        isOpen={isOpen}
                        error={error}
                        loading={loading}
                        order_number={order_number}
                        requestID={requestID}
                        onToggleModal={this._handleToggleModal}
                        handleSubmit={this._handleSubmit}
                    />
                )}
            </div>
        )
    }
}

ForceApprove.propTypes = {
    requestID: PropTypes.string.isRequired,
    order_number: PropTypes.string.isRequired
}

export default ForceApprove