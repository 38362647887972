import api from "../api"
import buildQuery from "../../helpers/query/buildQuery"

export const createSupplierValidation = (data) => {
    return api.makeAuthRequest({
        url: `suppliers/requests`,
        method: 'POST',
        data: data
    })
}

export const getSupplierValidation = (params) => {
    const query = buildQuery(params)

    return api.makeAuthRequest({
        url: `suppliers/requests?${query}`,
        method: 'GET'
    })
}

export const approveSupplierValidation = (request_id) => {
    return api.makeAuthRequest({
        url: `suppliers/requests/${request_id}/approve`,
        method: 'POST'
    })
}

export const rejectSupplierValidation = (request_id) => {
    return api.makeAuthRequest({
        url: `suppliers/requests/${request_id}/reject`,
        method: 'POST'
    })
}