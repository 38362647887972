import React, {Component} from 'react'
import moment from 'moment'
import InvoiceResults from './InvoiceResults'
import {Link} from "react-router-dom"
import PropTypes from "prop-types"
import humanizeTime from "../../../../helpers/time/humanizeTime"
import ActionApprove from './../action/ActionApprove'
import ActionReject from "../action/ActionReject"
import PubSub from "pubsub.js"
import {getItemDetail} from '../../../../services/api/InvoiceValidationServices'
import ForceApprove from "../action/ForceApprove"

class InvoiceItemPage extends Component {
    state = {
        item: {},
        error: '',
        loading: false,
        results: []
    }

    _token = null

    componentDidMount() {
        this._getItemIvoice()
        this._token = PubSub.subscribe('LOAD_DETAIL_INVOICE', () => {
            this._getItemIvoice()
        })
    }

    componentWillUnmount() {
        this._token && PubSub.unsubscribe(this._token)
    }

    _getItemIvoice = async () => {
        try {
            const {idInvoice} = this.props

            this.setState({
                loading: true,
                error: ''
            })

            const {data, message, success} = await getItemDetail(idInvoice)

            if(!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState({
                item: data,
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _getStatus = (value) => {
        switch (value) {
            case 'done':
                return <span className="badge badge-success">Done</span>
            case 'created':
                return <span className="badge badge-secondary">Created</span>
            case 'processing':
                return <span className="badge badge-warning">Processing</span>
            default:
                return <span className="badge badge-danger">Failed</span>
        }
    }

    _getApprovedStatus = (status) => {
        switch (status) {
            case 'rejecting':
                return <span className="badge badge-secondary">Rejecting</span>
            case 'rejected':
                return <span className="badge badge-danger">Rejected</span>
            case 'approving':
                return <span className="badge badge-secondary">Approving</span>
            case 'approved':
                return <span className="badge badge-success">Approved</span>
            default:
                return
        }
    }

    _validateDate = (date) => {
        const momentTime = moment(date)

        return humanizeTime(momentTime)
    }

    render() {
        const {idInvoice} = this.props
        const {item, error} = this.state
        const {results_statuses_count, shipping_carrier, approval_status} = item
        const backURL = this.props.currentPage === 1 ? '/a/invoice-validation' : `/a/invoice-validation?page=${this.props.currentPage}`

        return (
            <div className="InvoiceContainer mt-3">
                <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex flex-column align-items-start">
                        <div className="mb-1">
                            <Link to={backURL}>
                                <i className="fas fa-chevron-left mr-1"/> Invoice validation
                            </Link>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <h1 className="mb-0 mr-3 PageTitle">{item.filename} (#{item.request_number})</h1>
                            {item.status && <h5 className="mb-0">{this._getStatus(item.status)}</h5>}
                        </div>
                    </div>
                    <div className="action d-flex align-items-center mb-3">
                        {(results_statuses_count !== undefined && results_statuses_count.weight_failed !== undefined && results_statuses_count.weight_failed > 0 && approval_status === 'approved' && !item.hasOwnProperty('force_approval_weight_failed_at')) && (
                            <ForceApprove
                                requestID={idInvoice}
                                order_number={item.filename}/>
                        )}
                    </div>
                    {(item._id && item.approval_status === undefined) && (
                        <div className="action d-flex align-items-center mb-3">
                            <ActionApprove
                                requestID={item._id}
                                requestNumber={item.request_number}
                                isDetail={true}
                            />
                            <ActionReject
                                requestID={item._id}
                                requestNumber={item.request_number}
                                isDetail={true}
                            />
                        </div>
                    )}
                </div>
                <div className="site-layout-background p-3">
                    {item.filename !== undefined && (
                        <div className="mt-3">
                            {error && (
                                <div className="text-danger mb-3">{error}</div>
                            )}
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="rowInfoInvoice d-flex px-3">
                                        <label>File name: </label> {item.filename}
                                    </div>
                                    <div className="rowInfoInvoice px-3">
                                        <label>Request number: </label> {item.request_number}
                                    </div>
                                    {(item.approval_status !== undefined && item.approval_status !== '') && (
                                        <div className="rowInfoInvoice d-flex px-3">
                                            <label>Approve status: </label>
                                            <div>{this._getApprovedStatus(item.approval_status)}</div>
                                        </div>
                                    )}
                                    <div className="rowInfoInvoice d-flex px-3">
                                        <label>Note: </label> {item.note}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="rowInfoInvoice d-flex px-3">
                                        <label>Created: </label> {this._validateDate(item.created)}
                                    </div>
                                    <div className="rowInfoInvoice d-flex px-3">
                                        <label>Finished at: </label> {this._validateDate(item.finished_at)}
                                    </div>
                                    <div className="rowInfoInvoice d-flex px-3">
                                        <label>Results statuses: </label>
                                        {results_statuses_count !== undefined && (
                                            <div>
                                                {results_statuses_count.done && (
                                                    <span className="mr-3"><span
                                                        className="badge badge-success mr-1">Done</span> {results_statuses_count.done} records</span>
                                                )}
                                                {results_statuses_count.failed && (
                                                    <span className="mr-3"><span
                                                        className="badge badge-danger mr-1">Failed</span> {results_statuses_count.failed} records</span>
                                                )}
                                                {results_statuses_count.weight_failed && (
                                                    <span><span
                                                        className="badge badge-danger mr-1">Failed weight</span> {results_statuses_count.weight_failed} records</span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {item.status !== undefined && (
                        <InvoiceResults request_id={idInvoice} shipping_carrier={shipping_carrier} filename={item.filename} status={item.status}/>
                    )}
                </div>
            </div>
        )
    }
}

InvoiceItemPage.propTypes = {
    idInvoice: PropTypes.string.isRequired,
}

export default InvoiceItemPage