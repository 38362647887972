import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import SupplierValidationFilter from "./SupplierValidationFilter"
import TableLoading from "../../../shared/TableLoading"
import PagePagination from "../../../shared/PagePagination"
import moment from "moment"
import humanizeTime from "../../../helpers/time/humanizeTime"
import {getSupplierItemListReports} from "../../../services/api/InvoiceValidationServices"
import {Tooltip} from 'reactstrap'

SupplierValidationResult.propTypes = {
    status: PropTypes.string,
    idValidation: PropTypes.string,
    filename: PropTypes.string
}

function SupplierValidationResult(props) {
    const {status, idValidation, filename} = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [data, setData] = useState([])
    const [filter, setFilter] = useState({
        approval_status: '',
        partner: '',
        status: '',
        order_number: ''
    })
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [limit] = useState(50)
    const [total, setTotal] = useState(0)
    const [tooltipOpen, setTooltipOpen] = useState({})

    const _getSupplierListResults = async () => {
        setLoading(true)
        setError('')

        try {
            const {status, order_number, payment_status} = filter
            const query = {
                idValidation,
                page,
                limit,
                status,
                payment_status,
                order_number,
            }

            const {data, message, success} = await getSupplierItemListReports(idValidation, query)

            if (!success) {
                setLoading(false)

                return setError(message || 'Get data error')
            }

            const {results, pages, total} = data

            setLoading(false)
            setPages(pages)
            setTotal(total)
            setData(results)
        } catch (e) {
            setLoading(false)
            setError(e.message)
        }
    }

    const _genStatus = (status) => {
        if (status === 'done') {
            return <span className="badge badge-success">Done</span>
        } else if (status === 'failed') {
            return <span className="badge badge-danger">Failed</span>
        } else if (status === 'weight_failed') {
            return <span className="badge badge-danger">Failed weight</span>
        } else if (status === 'pending') {
            return <span className="badge badge-secondary">Pending</span>
        } else if (status === 'paid') {
            return <span className="badge badge-success">Paid</span>
        }
    }

    const _validateDate = (date) => {
        const momentTime = moment(date)

        return humanizeTime(momentTime)
    }

    const _switchPage = (page) => {
        setPage(page)
    }

    const _onHandleChangeInput = (name, value) => {
        setPage(1)
        setFilter({
            ...filter,
            [name]: value
        })
    }

    const _onHandleResetFilter = name => {
        setPage(1)
        setFilter({
            ...filter,
            [name]: ''
        })
    }

    const _onHandleChangeStatus = (e) => {
        setPage(1)
        setFilter({
            ...filter,
            status: e.target.value
        })
    }

    const _onHandleChangePaymentStatus = (e) => {
        setPage(1)
        setFilter({
            ...filter,
            payment_status: e.target.value
        })
    }

    const toggleTooltip = (type, id) => () => {
        const newTooltipOpen = {...tooltipOpen}

        if (!newTooltipOpen[type]) {
            newTooltipOpen[type] = {[id]: 1}
        } else {
            if (newTooltipOpen[type][id]) {
                newTooltipOpen[type][id] = 0
            } else {
                newTooltipOpen[type][id] = 1
            }
        }

        setTooltipOpen(newTooltipOpen)
    }

    useEffect(() => {
        _getSupplierListResults()
        // eslint-disable-next-line
    }, [filter, page])

    return (
        <div className='SupplierValidationResult'>
            <SupplierValidationFilter
                filter={filter}
                idInvoice={idValidation}
                total={total}
                filename={filename}
                onHandleChangePaymentStatus={_onHandleChangePaymentStatus}
                onHandleChangeInput={_onHandleChangeInput}
                onHandleChangeStatus={_onHandleChangeStatus}
                onHandleResetFilter={_onHandleResetFilter}
            />
            {error && (
                <div className="text-danger mb-3">{error}</div>
            )}
            <table className="table table-striped">
                <thead className="thead-light">
                <tr>
                    {/* <th className="colOrderNumber">Line CSV</th> */}
                    <th className="colMCOrderId">mc_order_id</th>
                    <th className="colSku">SKU</th>
                    <th className="colMessage">Message</th>
                    <th className="colCreated">Created</th>
                    <th className="colCheckingNumber">Package name</th>
                    <th className="text-center colStatus">Status</th>
                </tr>
                </thead>
                {
                    (['processing', 'created'].includes(status)) ? (
                        <tbody>
                        <tr>
                            <td colSpan={6}>In processing</td>
                        </tr>
                        </tbody>
                    ) : (
                        loading ?
                            <TableLoading columnQuantity={6}/>
                            :
                            <tbody>
                            {data.length > 0 ? (
                                data.map((item, index) => {
                                    const {invoice_records, _id: itemId} = item
                                    const {sku, mc_order_id, _order_data, fulfillment} = invoice_records[0]
                                    const {
                                        supplier: otherSupplier,
                                        quantity = 0,
                                        csv_quantity = 0,

                                    } = {..._order_data}
                                    return (
                                        <tr id={`tooltip-${itemId}`} key={item._id}
                                            className={index % 2 === 0 ? 'odd' : 'even'}>
                                            <td>{mc_order_id}</td>
                                            <td>{sku}</td>
                                            <td className="colMessage">
                                                <span>{item.message}</span>
                                                {
                                                    item.message_code === 'NOT_OWNER_ORDER' && otherSupplier &&
                                                    <>
                                                        <Tooltip
                                                            toggle={toggleTooltip('message', itemId)}
                                                            isOpen={tooltipOpen['message'] && tooltipOpen['message'][itemId]}
                                                            target={`tooltip-${itemId}`}>{`Đơn của supplier ${otherSupplier}`}</Tooltip>
                                                    </>
                                                }

                                                {
                                                    item.message_code === 'QUANTITY_NOT_MATCH' &&
                                                    <>
                                                        <Tooltip
                                                            toggle={toggleTooltip('message', itemId)}
                                                            isOpen={tooltipOpen['message'] && tooltipOpen['message'][itemId]}
                                                            target={`tooltip-${itemId}`}>{`Số lượng thực tế ${quantity}, số lượng đối soát ${csv_quantity}`}</Tooltip>
                                                    </>
                                                }

                                                {
                                                    item.message_code === 'DUPLICATE_ROW' &&
                                                    <>
                                                        <Tooltip
                                                            toggle={toggleTooltip('message', itemId)}
                                                            isOpen={tooltipOpen['message'] && tooltipOpen['message'][itemId]}
                                                            target={`tooltip-${itemId}`}>{`Trùng thông tin order của MC là ${mc_order_id}`}</Tooltip>

                                                    </>
                                                }
                                            </td>
                                            <td>{_validateDate(item.created)}</td>
                                            {/* <td>{!!_order_data && _order_data.package_name}</td> */}
                                            <td>{!!fulfillment && fulfillment.name}</td>
                                            <td className="text-center">
                                                {_genStatus(item.status)}
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={6}>No results</td>
                                </tr>
                            )}
                            </tbody>
                    )
                }
            </table>
            {!loading && pages > 1 && (
                <div className="TableNav mt-5 d-flex justify-content-end">
                    <PagePagination page={page} pages={pages} onChangePage={_switchPage}/>
                </div>
            )}
        </div>
    )
}

export default SupplierValidationResult
