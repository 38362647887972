import React, {Component} from 'react'
import FilterLabel from './FilterLabel'
import PagePagination from "../../../shared/PagePagination"
import changeQuerySearch from '../../../helpers/routing/changeQuerySearch'
import {getListLabels} from '../../../services/api/LabelServices'
import LabelsTable from './LabelsTable'
import { getListShippingCarrier } from '../../../services/api/ShippingCarrierServices'

class LabelPage extends Component {
    state = {
        data: [],
        error: '',
        loading: false,
        page: this.props.currentPage,
        pages: 1,
        limit: this.props.currentLimit,
        total: 0,
        filter: {
            order_number: '',
            partner_tracking_number: '',
            tracking_number: '',
            shipping_carrier: '',
            status: 'done',
            source: ''
        },
        ListShippingCarrier: []
    }

    componentDidMount() {
        this._handleGetListLabel()
        this.fetchListShippingCarrier()
    }

    fetchListShippingCarrier = async() => {
        try {
            const payload = {
                page: 1,
                limit: 10000,
            }
            const {data, success, message: mess} = await getListShippingCarrier(payload)
            if(!success) {
                throw new Error(mess)
            }
            this.setState({ListShippingCarrier: data.shippingCarriers || []})
        } catch (error) {
            this.setState({
                error: error.message,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setState({
                page: this.props.currentPage,
            }, this._handleGetListLabel)
        }
    }

    _handleGetListLabel = async () => {
        try {
            const {page, limit, filter} = this.state
            const {status, order_number, source, partner_tracking_number, tracking_number, shipping_carrier} = filter
            const query = {
                page,
                limit,
                status,
                order_number,
                partner_tracking_number,
                tracking_number,
                shipping_carrier,
                source
            }

            this.setState({
                loading: true,
                error: ''
            })

            const {success, data, message} = await getListLabels(query)

            if (!success) {
                return this.setState({
                    error: message || 'Get data error.',
                    loading: false
                })
            }

            const {labels, pages, total} = data

            this.setState({
                data: labels,
                pages: pages,
                total: total,
                loading: false
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })
        }
    }

    _onHandleChangePage = (e) => {
        this.setState({
            limit: e.target.value,
            page: 1
        }, () => {
            this._handleGetListLabel()
        })

        changeQuerySearch({limit: e.target.value}, true)
        changeQuerySearch({page: 1}, true)
    }

    _onHandleChangeStatus = (e) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [e.target.name]: e.target.value
            },
            page: 1
        }, () => {
            this._handleGetListLabel()
        })

        changeQuerySearch({page: 1}, true)
    }

    _switchPage = (page) => {
        this.setState({
            page: page
        }, () => {
            changeQuerySearch({page}, true)
            this._handleGetListLabel()
        })
    }

    _onHandleChangeInput = (name, value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: value
            },
            page: 1
        }, () => {
            if (this.timer) clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this._handleGetListLabel()
            }, 350)
        })

        changeQuerySearch({page: 1}, true)
    }

    _onHandleResetFilter = name => {
        this.setState({
            filter: {
                ...this.state.filter,
                [name]: ''
            }
        }, () => {
            this._handleGetListLabel()
        })

        changeQuerySearch({page: 1}, true)
    }

    render() {
        const { error, data, filter, limit, page, pages, total, loading, ListShippingCarrier } = this.state
        const shippingCarriers = ListShippingCarrier.length > 0 ? ListShippingCarrier.map(i=>({
            value: i.slug,
            name: i.name,
        })).sort((a, b) => {
            return a.name.localeCompare(b.name)
        }) : []

        return (
            <div className="LabelsPage">
                <FilterLabel
                    limit={limit}
                    filter={filter}
                    total={total}
                    onHandleChangePage={this._onHandleChangePage}
                    onHandleChangeStatus={this._onHandleChangeStatus}
                    onHandleChangeInput={this._onHandleChangeInput}
                    onHandleResetFilter={this._onHandleResetFilter}
                    shippingCarriers={shippingCarriers || []}
                />
                {error && (
                    <div className="text-danger my-3">{error}</div>
                )}
                <div className="wrapTable">
                    <LabelsTable data={data} loading={loading}/>
                </div>
                <div className="TableNav mt-5 d-flex justify-content-end">
                    <PagePagination page={page} pages={pages} onChangePage={this._switchPage}/>
                </div>
            </div>
        )
    }
}

export default LabelPage
